import React from 'react';
import Header from './components/Header';
// import HeroSection from './components/HeroSection';
// import BannerSection from './components/BannerSection';
import StatCounter from './thirdPartyComponents/statcounter';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import TicTacToe from './Games/TicTacToe';
import HtmlCss from './Portfolio/html-css';
import Home from './Home';
import AboutMe from './Pages/AboutMe';
import ContactUs from './Pages/ContactUs';
import FacebookShareHeader from './thirdPartyComponents/facebookShare';


import "./css/style.css";


const App = ()=>{
  var currentPath = (window.location.href).split("/");

  return(
    <div>
      <FacebookShareHeader/>
      <Header page={currentPath[currentPath.length - 1]}/>
      <Router>
        <Routes>
          <Route exact path='/' element={<Home />} />
          <Route path='/Portfolio/HTML-CSS'element={<HtmlCss/>}/>
          <Route path='/Games/TicTacToe'element={<TicTacToe/>}/>
          <Route path='/AboutMe'element={<AboutMe/>}/>
          <Route path='/ContactUs'element={<ContactUs/>}/>
        </Routes> 
         
        <StatCounter/>
        </Router>  
      
    </div>
  );
}

export default App; 