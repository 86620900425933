import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import "../Games.css"


function PrasanthCallToAction() {
    return(
        <div>
            <div className="Message">
                Hey! I know you liked this game.<br/>
                More fun on the way
            </div>
            {/* Facebook share button */}
            {/* <div class="fb-share-button fb-share-button-align-center" data-href="https://prasanthkj.com/Games/TicTacToe" data-layout="button" data-size="large"><a target="_blank" href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fprasanthkj.com%2FGames%2FTicTacToe&amp;src=sdkpreparse" class="fb-xfbml-parse-ignore">Share</a></div> */}

        </div>
    );
}

export default PrasanthCallToAction;