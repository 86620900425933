import "./css/header.css"

const Header=({page=""})=>{
    console.log("headerzzz"+page);
    return(
        <div className="headerSection">
            <header>
                <div className="mainHeader">
                    <ul className="navbar-menu-ul top-navbar">                        
                        <li class={(page === "")?"ActivePage":"InActivePage"}>
                            <a href="/">Home</a>
                        </li>
                        <li class={(page === "HTML-CSS")?"ActivePage":"InActivePage"}>
                            <a href="/Portfolio/HTML-CSS">HTML-CSS Projects</a>
                        </li>
                        <li class={(page === "TicTacToe")?"ActivePage":"InActivePage"}>                   
                            <a href="/Games/TicTacToe">TicTacToe</a>
                        </li>
                        <li class={(page === "ContactUs")?"ActivePage":"InActivePage"}>                   
                            <a href="/ContactUs">Contact Us</a>
                        </li>
                        <li class={(page === "AboutMe")?"ActivePage":"InActivePage"}>                   
                            <a href="/AboutMe">About Me</a>
                        </li>
                        
                    </ul>
                </div>
            </header>
        </div>
    );
}

export default Header;