import PortfolioCard from "../components/PortfolioCard";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./css/portfolio.css"

const HtmlCss = () => {
    return(
        <div>
            <div className="portfolio-website-container">
                <div className="row justify-content-around">
                    {/* Project 1 */}
                    <div className="d-flex justify-content-center col-xxl-5 col-xl-6 col-lg-6 mt50">
                        <PortfolioCard 
                        title="Fashion Blog Website"
                        imgLink="https://raw.githubusercontent.com/PrasanthKJ/Fashion-Blog/main/1.png"
                        liveDemoLink = "https://fashion-blog-1-prasanthkj.netlify.app/"
                        githubLink = "https://github.com/PrasanthKJ/Fashion-Blog"/>
                    </div>
                    {/* Project 2 */}
                    <div className="d-flex justify-content-center col-xxl-5 col-xl-6 col-lg-6 mt50 mt50-mobile ">
                        <PortfolioCard 
                        title = "Restaurant Landing Page"
                        imgLink="https://raw.githubusercontent.com/PrasanthKJ/Restaurant-Landing-Page-1/main/2.png"
                        liveDemoLink = "https://restaurant-landing-page-1-prasanthkj.vercel.app/"
                        githubLink = "https://github.com/PrasanthKJ/Restaurant-Landing-Page-1"/>
                    </div>
                    {/* Project 3 */}
                    <div className="d-flex justify-content-center col-xxl-5 col-xl-6 col-lg-6 mt50 mt50-mobile">
                        <PortfolioCard 
                        title= "Lawfirm Landing Page"
                        imgLink="https://raw.githubusercontent.com/PrasanthKJ/Lawfirm-Landing-Page-1/main/thumbnail.png"
                        liveDemoLink = "https://lawfirm-landing-page-1-prasanthkj.netlify.app/"
                        githubLink = "https://github.com/PrasanthKJ/Lawfirm-Landing-Page-1"/>
                    </div>
                    {/* Project 4 */}
                    <div className="d-flex justify-content-center col-xxl-5 col-xl-6 col-lg-6 mt50 mt50-mobile">
                        <PortfolioCard 
                        title= "Techservice Landing Page"
                        imgLink="https://raw.githubusercontent.com/PrasanthKJ/techservice-landing-page-1/main/thumbnail.png"
                        liveDemoLink = "https://techservice-landing-page-1-prasanthkj.netlify.app/"
                        githubLink = "https://github.com/PrasanthKJ/techservice-landing-page-1"/>
                    </div>
                    {/* Project 5 */}
                    <div className="d-flex justify-content-center col-xxl-5 col-xl-6 col-lg-6 mt50 mt50-mobile">
                        <PortfolioCard
                        title= "DAO Website Landing Page" 
                        imgLink="https://raw.githubusercontent.com/PrasanthKJ/dao-website-landing-page-1/main/5.png"
                        liveDemoLink = "https://dao-website-landing-page-1-prasanthkj.netlify.app/"
                        githubLink = "https://github.com/PrasanthKJ/dao-website-landing-page-1"/>
                    </div>
                    {/* Project 6 */}
                    <div className="d-flex justify-content-center col-xxl-5 col-xl-6 col-lg-6 mt50 mt50-mobile">
                        <PortfolioCard 
                        title= "Flower Shop Landing Page"
                        imgLink="https://raw.githubusercontent.com/PrasanthKJ/flowershop-landing-page-1/main/thumbnail.png"
                        liveDemoLink = "https://flowershop-landing-page-1-prasanthkj.netlify.app/"
                        githubLink = "https://github.com/PrasanthKJ/flowershop-landing-page-1"/>
                    </div>
                    {/* Project 7 */}
                    <div className="d-flex justify-content-center col-xxl-5 col-xl-6 col-lg-6 mt50 mt50-mobile">
                        <PortfolioCard 
                        title= "Headphone Product Landing Page"
                        imgLink="https://raw.githubusercontent.com/PrasanthKJ/headphone-landing-page-1/main/7.png"
                        liveDemoLink = "https://headphone-landingpage-1-prasanthkj.netlify.app/"
                        githubLink = "https://github.com/PrasanthKJ/headphone-landing-page-1"/>
                    </div>
                    
                </div> {/* row row-desktop */}
            </div> {/*container*/}
        </div>
    )
}

export default HtmlCss;