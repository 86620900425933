import "./css/heroSection.css"

const HeroSection = ()=>{
    return (
        <div className="heroSection">
            <img src={require("../images/PrasanthKJ-Logo-Square-234px.jpg")} alt="Prasanth KJ" className="heroImage"/>
        </div>
    )
}

export default HeroSection;