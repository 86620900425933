import "./css/portfoliocard.css";

const PortfolioCard = ({title, imgLink, liveDemoLink, githubLink}) => {
    return(
        <div className="portfolio-card">
            <div className="portfolio-card-row-1">
                <h3 className="portfolio-title">{title}</h3>
            </div>
            <div className="portfolio-card-row-2">
                <div className="card-left-content">
                    <img src = {imgLink} className="portfolio-card-img" alt=""/>
                </div>
                <div className="card-right-content">
                    <button className="btn btn-livedemo" onClick={()=>(window.open(liveDemoLink,   '_blank'))} >Live Demo</button>
                    <button className="btn btn-github ml20" onClick={()=>(window.open(githubLink,   '_blank'))} >Github</button>
                </div>
            </div>
        </div>
    )
}
export default PortfolioCard;