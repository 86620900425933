const FacebookShareHeader = ()=>{
    return(
        <div>
            <div id="fb-root"></div>
            <script async defer crossorigin="anonymous" src="https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v14.0&appId=352016822383317&autoLogAppEvents=1" nonce="8cHwd9Ty"></script>
        </div>
    );
};

export default FacebookShareHeader;
