import "./css/pages.css"

const AboutMe = ()=>{
    return(
        <div className="page about-me-page">
            <div className="page-heading ">
                <h1 className="text-transform text-align-center">About Me</h1>
            </div>
            <div className="aboutme-contents mt50">
                <div className="leftcontent">
                    <img src={require("../images/PrasanthKJ-Logo-Square-234px.jpg")} alt="Prasanth KJ"/>
                    <h1 className="profile-name mt15">Prasanth KJ</h1>
                </div>
                <div className="rightcontent">
                    <h3 className="text-align-center-only-mobile">Hi, I’m Prasanth, coder by profession.</h3>
                    <h4 className="mt20 text-align-center-only-mobile">I’m developing websites to create growth-driven products and brands.</h4>
                    <h4 className="mt20 text-align-center-only-mobile">I read more tech posts, create software applications for the web. A few of those thoughts turn into blog posts from time to time.</h4>
                    <h3 className="mt50 text-align-center-only-mobile">#IWriteCode</h3>
                </div>
            </div>
        </div>
    )
}

export default AboutMe;