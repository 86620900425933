import React from "react"

import {FaTimes, FaPen, FaRegCircle} from 'react-icons/fa';
// import  {BiBarcode} from 'react-icons/bi';


const Icon = ({name}) => {
    switch(name){
        case 'circle':
            return <FaRegCircle className="icon" />
        case 'cross':
            return <FaTimes className="icon" />
        default:
            return <FaPen className="icon" />
    }
}

export default Icon;