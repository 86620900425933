import React from 'react';
import HeroSection from './components/HeroSection';
import BannerSection from './components/BannerSection';

import "./css/style.css"

const Home = ()=>{
  return(
    <div>
        <HeroSection/>
        <BannerSection/>     
    </div>
  );
}

export default Home; 