const StatCounter = ()=> {
    return(
            /* Default Statcounter code for Prasanth KJ https://prasanthkj.com */
            <div>                
                <script type="text/javascript">
                    var sc_project=12742983; 
                    var sc_invisible=1; 
                    var sc_security="3896a940"; 
                </script>
                <script type="text/javascript" src="https://www.statcounter.com/counter/counter.js"
                async>                    
                </script>                
                <div className="statcounter">
                    <a title="Web Analytics" href="https://statcounter.com/" target="_blank" rel="noreferrer"> <img className="statcounter" src="https://c.statcounter.com/12742983/0/3896a940/1/" alt="Web Analytics" referrerPolicy="no-referrer-when-downgrade"/></a>
                </div>
            </div>
            /* End of Statcounter Code */        
    );
}

export default StatCounter;