import "./css/pages.css";
import "../css/style.css";
import HubspotForm from 'react-hubspot-form';

const ContactUs = () => {
    return(
        <div className="content-width-70pc bg-white m-auto p-t50 p-r50-l50 border-r5">
            <HubspotForm
                portalId='21800432'
                formId='564da98a-6761-44ef-86b8-fe589850b562'
                onSubmit={() => console.log('Submit!')}
                onReady={(form) => console.log('Form ready!')}
                loading={<div>Loading...</div>}
                />
        </div>
    );
}

export default ContactUs;